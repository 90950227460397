import { handleFetchError } from '@iso/lib/helpers/errorHandle';

const BASE_URL = process.env.REACT_APP_GATEWAY_URL;

const fetchEntities = async (url, entity, startDate, endDate) => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');

  if (
    !entity ||
    entity === '' ||
    !startDate ||
    !endDate ||
    startDate === null ||
    endDate === null
  ) {
    return [];
  }

  try {
    let response = await fetch(url, {
      method: 'get',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    const status = await response.status;
    if (status === 200) {
      let data = await response.json();
      return data;
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
};

const fetchEntitiesFromMultipleRefs = async (url, startDate, endDate) => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');

  if (!startDate || !endDate || startDate === null || endDate === null) {
    return [];
  }

  try {
    let response = await fetch(url, {
      method: 'get',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    const status = await response.status;
    if (status === 200) {
      let data = await response.json();
      return data;
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
};

export const fetchEntity = async uri => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = BASE_URL + '/api/charging-session/uri/' + uri;
  try {
    let response = await fetch(url, {
      method: 'get',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    const status = response.status;
    if (status === 200) {
      let data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    return [];
  }
};

export const fetchStats = async (entities, startDate, endDate) => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');

  if (
    !entities ||
    entities === '' ||
    !startDate ||
    !endDate ||
    startDate === null ||
    endDate === null
  ) {
    return null;
  }

  let url =
    BASE_URL +
    '/api/charging-session/stats?' +
    'start-date=' +
    startDate +
    '&end-date=' +
    endDate;

  entities.map((e, _) => {
    url = url + '&entityUri=' + e;
  });

  try {
    let response = await fetch(url, {
      method: 'get',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });

    let data = await response.json();

    handleFetchError(response, data);

    return data;

    // const status = await response.status;
    // if (status === 200) {
    //   let data = await response.json();
    //   return data;
    // } else {
    //   return null;
    // }
  } catch (e) {
    throw e;
  }
};

export const fetchChargingSessions = async (entity, startDate, endDate) => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');

  let url =
    BASE_URL +
    '/api/charging-session?' +
    'entityUri=' +
    entity +
    '&start-date=' +
    startDate +
    '&end-date=' +
    endDate;

  return fetchEntities(url, entity, startDate, endDate);
};

export const fetchDailyStats = async (entities, startDate, endDate) => {
  let url =
    BASE_URL +
    '/api/charging-session/daily-stats?' +
    'start-date=' +
    startDate +
    '&end-date=' +
    endDate;

  entities.map((e, _) => {
    url = url + '&entityUri=' + e;
  });

  return fetchEntitiesFromMultipleRefs(url, startDate, endDate);
};

export const fetchActiveChargingSessions = async entities => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');

  let url = BASE_URL + '/api/charging-session/active';

  if (!entities || entities === undefined) {
    return [];
  }

  var i;
  for (i = 0; i < entities.length; i++) {
    if (i === 0) url += '?entityUri=' + entities[i];
    else url += '&entityUri=' + entities[i];
  }

  try {
    let response = await fetch(url, {
      method: 'get',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    if (response.ok) {
      let data = await response.json();
      return data;
    } else {
      return [];
    }
  } catch (e) {
    throw e;
  }
};

export const fetchChargingSessionsFromMultipleEntities = async (
  entities,
  startDate,
  endDate
) => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');

  let url =
    BASE_URL +
    '/api/charging-session?' +
    'start-date=' +
    startDate +
    '&end-date=' +
    endDate;

  var i;
  for (i = 0; i < entities.length; i++) {
    url += '&entityUri=' + entities[i];
  }

  try {
    let response = await fetch(url, {
      method: 'get',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    let data = await response.json();
    return data;
  } catch (e) {
    throw e;
  }
};

export const fetchStats2 = async (entities, startDate, endDate) => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');

  if (
    !entities?.length ||
    !startDate ||
    !endDate ||
    startDate === null ||
    endDate === null
  ) {
    return null;
  }

  let url =
    BASE_URL +
    '/api/charging-session/stats2?' +
    'start-date=' +
    startDate +
    '&end-date=' +
    endDate;

  entities.map((val, i) => {
    url += `&entityUri=${val}`;
  });

  try {
    let response = await fetch(url, {
      method: 'get',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    const status = response.status;
    if (status === 200) {
      let data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    throw e;
  }
};

export const boostChargingSessions = async csUri => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = `${BASE_URL}/api/charging-session/boost/${csUri}`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        Authorization: bearer,
        'content-type': 'application/json',
      }),
    });

    if (response.status === 200 || response.status === 204) return 'OK';

    let data = await response.json();

    if (data?.error) throw data.error;
    return data;
  } catch (e) {
    throw e;
  }
};

export const fetchLastCharngingSessionsFromFleet = async fleetUri => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = `${BASE_URL}/api/charging-session/latest/list?entityUri=${fleetUri}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        Authorization: bearer,
        'content-type': 'application/json',
      }),
    });

    let data = await response.json();

    if (data?.error) throw data.error;
    return data;
  } catch (e) {
    throw e;
  }
};