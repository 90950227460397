import qs from 'qs';
import options from '../../../packages/biadashboard/src/containers/Sidebar/options';
import { isServer } from './isServer';

export function getInitData() {
  if (!isServer) {
    const initData = qs.parse(window.location.search.slice(1));
    if (initData.toggle)
      initData.toggle.free_shipping =
        initData.toggle.free_shipping === 'true' ? true : undefined;
    return initData;
  }
  return false;
}
export function setUrl(searchState) {
  if (!isServer) {
    const search = searchState
      ? `${window.location.pathname}?${qs.stringify(searchState)}`
      : '';
    window.history.pushState(searchState, null, search);
  }
  return;
}

export function getDefaultPath() {
  const getParent = (beforeLastRoute, lastRoute) => {
    const parent = [];
    const item = [];
    if (lastRoute) item.push(lastRoute);
    if (beforeLastRoute && beforeLastRoute !== 'dashboard') {
      parent.push(beforeLastRoute);
    } else parent.push(lastRoute);

    return {
      parent,
      item,
    };
  };
  if (!isServer && window.location.pathname) {
    const routes = window.location.pathname.split('/');
    if (routes.length > 1) {
      const beforeLastRoute = routes[routes.length - 2];
      const lastRoute = routes[routes.length - 1];
      return getParent(beforeLastRoute, lastRoute);
    }
  }
  return [];
}
