import React, { lazy, Suspense } from 'react';
import {
  Route,
  BrowserRouter,
  Routes,
  Navigate,
} from 'react-router-dom';

import NotFound from '@iso/containers/Pages/404/404';
// import ErrorBoundary from './ErrorBoundary';
import * as Sentry from "@sentry/react";
import Loader from '@iso/components/utility/loader';
import Secured from '@iso/containers/Authentication/Keycloak/Secured';
import { useSelector } from 'react-redux';
import themes from '@iso/config/theme/theme.config';

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));
const App = lazy(() => import('./containers/App/App'));

const OLD_LANDING_PAGE = '/dashboard/realtime';
export const LANDING_PAGE = '/dashboard/realtime/sockets';

export default function RoutesComponent() {
  const [baseUrl, setBaseUrl] = React.useState();
  const { keycloak } = useSelector(state => state.Auth);
  const token = keycloak?.token;
  const theme = keycloak?.tokenParsed?.theme;
  const customUrl = theme && themes[theme]?.customUrl;

  React.useEffect(() => {
    if (!token) return;
    if (customUrl) setBaseUrl(customUrl);
  }, [token]);

  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <Routes>
            <Route path='*' element={<NotFound />}/>
            <Route path="/" element={<Navigate to={LANDING_PAGE} />} />
            <Route path="/dashboard" element={<Navigate to={LANDING_PAGE} />} />
            <Route path={OLD_LANDING_PAGE} element={<Navigate to={LANDING_PAGE} />} />
            <Route
              path={"/:customUrl?/dashboard"}
              element={<Secured />}
            >
              <Route path='*' element={<Dashboard baseUrl={baseUrl} />} />
            </Route>
            <Route
              path="/app"
              element={<Secured />}
            >
              <Route path='*' element={<App />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Suspense>
    </Sentry.ErrorBoundary>
  );
}